import { Loader, useMantineTheme } from '@mantine/core'
import type { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { useAuth } from '@/plugins/auth'

import Loading from '../Loading'
import RootButton from './RootButton'

interface Web3ButtonProps {
  signOutLink?: boolean
  style1?: boolean
}

const Web3Button: FC<Web3ButtonProps> = ({ signOutLink = false, style1 = true }) => {
  const { t } = useTranslation()
  const { status, connect, disconnect } = useAuth()
  const theme = useMantineTheme()

  if (status === 'connecting') {
    return (
      <RootButton
        disabled
        secondary
        leftSection={<Loader color={theme.colors.textAndIcon[0]} size="xs" />}
      >
        {t('buttons.loadingWeb3Auth', 'Loading Web3Auth...')}
      </RootButton>
    )
  }

  if (status === 'connected') {
    return signOutLink ? (
      <Link style={{ color: theme.colors.title[0] }} to="" onClick={disconnect}>
        {t('buttons.signOut', 'Sign Out')}
      </Link>
    ) : (
      <RootButton onClick={disconnect} style1={style1}>
        {t('buttons.signOut', 'Sign Out')}
      </RootButton>
    )
  }

  if (status === 'disconnected') {
    return (
      <RootButton onClick={connect} style1={style1}>
        {t('buttons.connect', 'Sign In')}
      </RootButton>
    )
  }

  return <Loading />
}

export default Web3Button
