import { gql } from '@apollo/client'

import { EARNED_COLLECTIONS_IDS } from '@/constants/blockchain'

import { baseItemFilters, publishedItemsFilter, suggestionsLimit } from './explore.schema'
import { Token, TokenBase, TotalOfItems, TransferLite } from './types'

// Search
export const ownedItemSearch = (searchString: string) => ({
  token: { metadata: { _cast: { String: { _iregex: searchString } } } },
})

// Filter
export const ownedItemFilter = (filteredBy: string) => {
  switch (filteredBy) {
    default:
      return {}
  }
}

// Sort
export const ownedItemSort = (sortedBy: string, direction: 'asc' | 'desc') => {
  switch (sortedBy) {
    // TODO: Add options
    default:
      return { updated_at_block: direction }
  }
}

// Suggestion
export const ownedItemSuggestions = (searchString: string) => ({
  token: { metadata: { _cast: { String: { _iregex: searchString } } } },
})

const earnedItemsCollectionsFilter = `{ collection_id: { _in: [${EARNED_COLLECTIONS_IDS.join(',')}] } }`

// User Owned / Created / Earned Number of Items Query

export type GetUserItemsCount = {
  totalOwned: {
    agg: {
      count: number
    }
  }
  totalPublished: {
    agg: {
      count: number
    }
  }
  totalEarned: {
    agg: {
      count: number
    }
  }
}

export const QUERY_GET_USER_ITEMS_COUNT = gql`
  query GetUserItemsCount($queryId: String!, $hiddenIds: [String!] = []) {
    totalOwned: balance_aggregate(
      where: {
        _and: [
          { token: {
            _and: [
              ${baseItemFilters}
              { id: { _nin: $hiddenIds } }
            ]
          } }
          { account: { _eq: $queryId } }
        ]
      }
    ) {
      agg: aggregate {
        count
      }
    }
    totalPublished: token_aggregate(
      where: {
        _and: [
          ${baseItemFilters}
          ${publishedItemsFilter}
          { creator_id: { _eq: $queryId } }
          { id: { _nin: $hiddenIds } }
        ]
      }
    ) {
      agg: aggregate {
        count
      }
    }
    totalEarned: balance_aggregate(
      where: {
        _and: [
          { token: {
            _and: [
              ${earnedItemsCollectionsFilter}
              { id: { _nin: $hiddenIds } }
            ]
          } }
          { account: { _eq: $queryId } }
        ]
      }
    ) {
      agg: aggregate {
        count
      }
    }
  }
`

// User Owned Items Query

export type GetOwnedItems = TotalOfItems & {
  balance: {
    amount: number
    token: TokenBase
  }[]
}

export const QUERY_GET_OWNED_ITEMS = gql`
  query GetOwnedItems(
    $limit: Int,
    $offset: Int,
    $search: balance_bool_exp!,
    $filter: balance_bool_exp!,
    $order: balance_order_by!,
    $queryId: String!,
    $hiddenIds: [String!] = []
  ) {
    balance(
      where: {
        _and: [
          { token: {
            _and: [
              ${baseItemFilters}
              { id: { _nin: $hiddenIds } }
            ]
          } }
          { account: { _eq: $queryId } }
          $search
          $filter
        ]
      }
      limit: $limit
      offset: $offset
      order_by: [$order, { id: asc }]
    ) {
      amount
      token {
        id
        nativeId: native_id
        creator: creator_id
        uri
        amount
        metadata
        createdAt: created_at
        createdAtBlock: created_at_block
      }
    }
    total: balance_aggregate(
      where: {
        _and: [
          { token: {
            _and: [
              ${baseItemFilters}
              { id: { _nin: $hiddenIds } }
            ]
          } }
          { account: { _eq: $queryId } }
          $search
          $filter
        ]
      }
    ) {
      agg: aggregate {
        count
      }
    }
  }
`
export interface GetSuggestedOwnedItems {
  balance: {
    token: {
      id: string
      nativeId: string
      metadata: any
    }
  }[]
}

export const QUERY_GET_SUGGESTED_OWNED_ITEMS = gql`
  query GetSuggestedOwnedItems(
    $search: balance_bool_exp!,
    $filter: balance_bool_exp!,
    $order: balance_order_by!,
    $queryId: String!,
    $hiddenIds: [String!] = []
  ) {
    balance(
      where: {
        _and: [
          { token: {
            _and: [
              ${baseItemFilters}
              { id: { _nin: $hiddenIds } }
            ]
          } }
          { account: { _eq: $queryId } }
          $search
          $filter
        ]
      }
      order_by: [$order, { id: asc }],
      limit: ${suggestionsLimit}
    ) {
      token {
        id
        nativeId: native_id
        metadata
      }
    }
  }
`

// User Created (Published) Items Query

export type GetCreatedItems = TotalOfItems & {
  token: TokenBase[]
}

export const QUERY_GET_CREATED_ITEMS = gql`
  query GetCreatedItems(
    $limit: Int,
    $offset: Int,
    $search: token_bool_exp!,
    $filter: token_bool_exp!,
    $order: token_order_by!,
    $queryId: String!,
    $hiddenIds: [String!] = []
  ) {
    token(
      where: {
        _and: [
          ${baseItemFilters}
          ${publishedItemsFilter}
          { creator_id: { _eq: $queryId } }
          { id: { _nin: $hiddenIds } }
          $search
          $filter
        ]
      }
      order_by: [$order, { id: asc }]
      limit: $limit
      offset: $offset
    ) {
      id
      nativeId: native_id
      creator: creator_id
      uri
      amount
      metadata
      createdAt: created_at
      createdAtBlock: created_at_block
    }
    total: token_aggregate(
      where: {
        _and: [
          ${baseItemFilters}
          ${publishedItemsFilter}
          { creator_id: { _eq: $queryId } }
          { id: { _nin: $hiddenIds } }
          $search
          $filter
        ]
      }
    ) {
      agg: aggregate {
        count
      }
    }
  }
`

export interface GetSuggestedCreatedItems {
  token: {
    id: string
    nativeId: string
    metadata: any
  }[]
}

export const QUERY_GET_SUGGESTED_CREATED_ITEMS = gql`
  query GetSuggestedCreatedItems(
    $search: token_bool_exp!,
    $filter: token_bool_exp!,
    $order: token_order_by!,
    $queryId: String!,
    $hiddenIds: [String!] = []
  ) {
    token(
      where: {
        _and: [
          ${baseItemFilters}
          { creator_id: { _eq: $queryId } }
          { id: { _nin: $hiddenIds } }
          $search
          $filter
        ]
      }
      order_by: [$order, { id: asc }],
      limit: ${suggestionsLimit}
    ) {
      id
      nativeId: native_id
      metadata
    }
  }
`

// User Earned Items Query

export type GetEarnedItems = TotalOfItems & {
  balance: {
    amount: number
    token: TokenBase
  }[]
}

export const QUERY_GET_EARNED_ITEMS = gql`
  query GetEarnedItems(
    $limit: Int,
    $offset: Int,
    $search: balance_bool_exp!,
    $filter: balance_bool_exp!,
    $order: balance_order_by!,
    $queryId: String!,
    $hiddenIds: [String!] = []
  ) {
    balance(
      where: {
        _and: [
          { token: {
            _and: [
              ${earnedItemsCollectionsFilter}
              { id: { _nin: $hiddenIds } }
            ]
          } }
          { account: { _eq: $queryId } }
          $search
          $filter
        ]
      }
      limit: $limit
      offset: $offset
      order_by: [$order, { id: asc }]
    ) {
      amount
      token {
        id
        nativeId: native_id
        creator: creator_id
        uri
        amount
        metadata
        createdAt: created_at
        createdAtBlock: created_at_block
      }
    }
    total: balance_aggregate(
      where: {
        _and: [
          { token: {
            _and: [
              ${earnedItemsCollectionsFilter}
              { id: { _nin: $hiddenIds } }
            ]
          } }
          { account: { _eq: $queryId } }
          $search
          $filter
        ]
      }
    ) {
      agg: aggregate {
        count
      }
    }
  }
`

export interface GetSuggestedEarnedItems {
  balance: {
    token: {
      id: string
      nativeId: string
      metadata: any
    }
  }[]
}

export const QUERY_GET_SUGGESTED_EARNED_ITEMS = gql`
  query GetSuggestedEarnedItems(
    $search: balance_bool_exp!,
    $filter: balance_bool_exp!,
    $order: balance_order_by!,
    $queryId: String!,
    $hiddenIds: [String!] = []
  ) {
    balance(
      where: {
        _and: [
          { token: {
            _and: [
              ${earnedItemsCollectionsFilter}
              { id: { _nin: $hiddenIds } }
            ]
          } }
          { account: { _eq: $queryId } }
          $search
          $filter
        ]
      }
      order_by: [$order, { id: asc }],
      limit: ${suggestionsLimit}
    ) {
      token {
        id
        nativeId: native_id
        metadata
      }
    }
  }
`

// Get an Item by ID (Item Page)

export type GetItemById = {
  token: Token
  itemIsMine: {
    amount: number
  } | null
  topOwners: {
    account: string
    amount: number
  }[]
} & TotalOfItems

export const QUERY_GET_ITEM_BY_ID = gql`
  query GetItemById($id: String!, $balanceId: String!, $limit: Int) {
    token: token_by_pk(id: $id) {
      id
      flagged
      nativeId: native_id
      creator: creator_id
      uri
      amount
      metadata
      createdAt: created_at
      createdAtBlock: created_at_block
      collection {
        id
        name
        symbol
        decimals
        collectionType: collection_type
        createdAt: created_at
        createdAtBlock: created_at_block
        creator: creator_id
      }
    }
    itemIsMine: balance_by_pk(id: $balanceId) {
      amount
    }
    topOwners: balance(
      where: { token: { id: { _eq: $id } } }
      order_by: [{ amount: desc }, { account: asc }]
      limit: $limit
    ) {
      account
      amount
    }
    total: balance_aggregate(where: { token: { id: { _eq: $id } } }) {
      agg: aggregate {
        count
      }
    }
  }
`

// Get the paginated item owners (Item Owners modal in Item Page)

export type GetItemOwners = {
  balance: {
    account: string
    amount: number
  }[]
}

export const QUERY_GET_ITEM_OWNERS = gql`
  query GetItemOwners($id: String!, $limit: Int, $offset: Int) {
    balance(
      where: { token: { id: { _eq: $id } } }
      order_by: [{ amount: desc }, { account: asc }]
      limit: $limit
      offset: $offset
    ) {
      account
      amount
    }
  }
`

export type GetItemTransfersById = TotalOfItems & {
  transfer: TransferLite[]
}
export const QUERY_GET_ITEM_TRANSFERS_BY_ID = gql`
  query GetItemTransfersById($where: transfer_bool_exp!, $limit: Int, $offset: Int) {
    transfer(order_by: { timestamp: desc }, limit: $limit, offset: $offset, where: $where) {
      from
      to
      amount
      timestamp
      transferType: transfer_type
      txnHash: txn_hash
    }
    total: transfer_aggregate(where: $where) {
      agg: aggregate {
        count
      }
    }
  }
`

// Get multiple Items by their IDs (Featured Items)

export type GetItemsByIds = {
  tokens: TokenBase[]
}

export const QUERY_GET_ITEMS_BY_IDS = gql`
  query GetItemsByIds($ids: [String!]!) {
    tokens: token(where: { id: { _in: $ids } }) {
      id
      nativeId: native_id
      creator: creator_id
      uri
      amount
      metadata
      createdAt: created_at
      createdAtBlock: created_at_block
    }
  }
`

export type SubscriptionTotalOwnedItems = TotalOfItems

export const SUB_TOTAL_OWNED_ITEMS = gql`
  subscription SubTotalOwnedItems($account: String!) {
    total: balance_aggregate(
      where: {
        _and: [
          { account: { _eq: $account } }
          { token: { collection: { collection_type: { _neq: "ERC20" } } } }
          { token: { flagged: { _eq: false } } }
        ]
      }
    ) {
      agg: aggregate {
        count
      }
    }
  }
`

// Get Content Auth (Spaces Feed)

export type GetContentAuth = {
  collection: {
    token: {
      id: string
    }
  }[]
  and: {
    token: {
      id: string
    }
  }[]
  or: {
    token: {
      id: string
    }
  }[]
}

export const QUERY_GET_CONTENT_AUTH = gql`
  query GetContentAuth(
    $whereCollection: balance_bool_exp!
    $whereAnd: balance_bool_exp!
    $whereOr: balance_bool_exp!
  ) {
    collection: balance(where: $whereCollection, order_by: { updated_at_block: asc }) {
      token {
        id
      }
    }

    and: balance(where: $whereAnd, order_by: { updated_at_block: asc }) {
      token {
        id
      }
    }

    or: balance(where: $whereOr, order_by: { updated_at_block: asc }) {
      token {
        id
      }
    }
  }
`
