import { useDisclosure } from '@mantine/hooks'
import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import Send from '@/components/Transfer/Send'

import ModalSkeleton from '../ModalSkeleton'
import ConfirmTransaction from '../Send/ConfirmTransaction'

interface SendModalProps {
  close: () => void
}

const SendModal: React.FC<SendModalProps> = ({ close }) => {
  const { t } = useTranslation()
  const [okToClose, setOkToClose] = useState<boolean>(true)
  const [openedAlert, { open: openAlert, close: closeAlert }] = useDisclosure(false)

  const handleDoubleConfirmation = useCallback(() => {
    setOkToClose(true)
    closeAlert()
    close()
  }, [setOkToClose, closeAlert, close])

  const handleCancel = useCallback(async () => {
    if (!okToClose) {
      openAlert()
    } else {
      close()
    }
  }, [close, okToClose, openAlert])

  return (
    <ModalSkeleton
      opened
      onClose={handleCancel}
      title={t('components.wallet.send', 'Send')}
      allowModalCloseOutside={false}
    >
      <>
        <ModalSkeleton
          opened={openedAlert}
          onClose={closeAlert}
          size="420px"
          title={t('components.send.confirmExitTxNotComplete', 'Warning: Nonrefundable')}
        >
          <ConfirmTransaction
            close={closeAlert}
            confirm={handleDoubleConfirmation}
            message={t(
              'components.send.confirmExitTxNotCompleteMessage',
              'You have already paid a nonrefundable bridge fee. If you close this window before completing your bridge transaction you will lose the fee. Are you sure you want to exit? Confirm if you want to exit.'
            )}
          />
        </ModalSkeleton>
        <Send close={handleCancel} setOkToClose={setOkToClose} />
      </>
    </ModalSkeleton>
  )
}

export default SendModal
