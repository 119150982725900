import { Stack, Text, Title, useMantineTheme } from '@mantine/core'
import { IconAlertTriangle, IconCheck } from '@tabler/icons-react'
import React, { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import RootButton from '@/components/Buttons/RootButton'
import TokenDisplay from '@/components/Tokens/TokensDisplay'
import {
  BRIDGE_AVAX_ERC20_REMOTE_ADDRESS,
  BRIDGE_L1_ERC20_REMOTE_ADDRESS,
  LL1_ADDRESS,
  NATIVE_CHAIN_TICKER,
  RL1_ADDRESS,
  STAKE_ADDRESS,
} from '@/constants/blockchain'
import { useBalancesContext } from '@/contexts/BalancesContext'
import { useChainFunctions } from '@/hooks/useChain'
import { avalancheWagmiConfig, l1NativeWagmiConfig } from '@/plugins/auth/config'

import ModalSkeleton from '../ModalSkeleton'

const ERROR_DISPLAY_TIME = 5000

interface TokenToAdd {
  // Lamina1
  RL1: boolean
  LL1: boolean
  STAKE: boolean
  AVAX: boolean
  all: boolean
  // Avalanche
  L1: boolean
}

interface ErrorAddingToken {
  // Lamina1
  RL1: string
  LL1: string
  STAKE: string
  AVAX: string
  all: string
  // Avalanche
  L1: string
}

interface AddTokenProps {
  addCall: () => void
  token: 'RL1' | 'LL1' | 'STAKE' | 'AVAX' | 'all' | 'L1'
  added: boolean
  isLoadingAction: boolean
  isError: string
  disabled: boolean
}

const AddToken: React.FC<AddTokenProps> = ({
  addCall,
  token,
  added,
  isLoadingAction,
  isError,
  disabled,
}) => {
  const { t } = useTranslation()
  const theme = useMantineTheme()
  return (
    <Stack
      style={{
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '50%',
      }}
    >
      {token === 'all' ? (
        <Title order={4}>{t('components.wallet.addTokenToMetamask.subheader', 'Tokens')}</Title>
      ) : (
        <TokenDisplay token={token === 'L1' ? NATIVE_CHAIN_TICKER : token} />
      )}
      <RootButton
        secondary
        style1={false}
        onClick={addCall}
        loading={isLoadingAction}
        disabled={added || disabled || isError !== ''}
      >
        {added && <IconCheck color={theme.colors.l1Primary[0]} style={{ margin: '0 4px' }} />}
        {!added && token === 'all' && t('buttons.addAll', 'Add All')}
        {!added && !isError && token !== 'all' && t('buttons.add', 'Add')}
        {isError && (
          <IconAlertTriangle color={theme.colors.notification[0]} style={{ margin: '0 4px' }} />
        )}
      </RootButton>
    </Stack>
  )
}

interface AddToMetamaskModalProps {
  network: string
  close: () => void
}

const AddToMetamaskModal: React.FC<AddToMetamaskModalProps> = ({ network, close }) => {
  const theme = useMantineTheme()
  const { t } = useTranslation()
  const {
    bridge: { hasBridgeAvax },
    ll1: { hasLL1 },
    rl1: { hasStaking },
  } = useBalancesContext()
  const { addTokenToMetamask } = useChainFunctions(l1NativeWagmiConfig)
  const { addTokenToMetamask: addTokenToMetamaskAvax } = useChainFunctions(avalancheWagmiConfig)

  const [added, setAdded] = useState<TokenToAdd>({
    RL1: false,
    LL1: false,
    STAKE: false,
    AVAX: false,
    all: false,
    L1: false,
  })
  const [isLoadingAction, setIsLoadingAction] = useState<TokenToAdd>({
    RL1: false,
    LL1: false,
    STAKE: false,
    AVAX: false,
    all: false,
    L1: false,
  })
  const [isError, setIsError] = useState<ErrorAddingToken>({
    RL1: '',
    LL1: '',
    STAKE: '',
    AVAX: '',
    all: '',
    L1: '',
  })

  const ll1andRl1Icon =
    'https://images.ctfassets.net/jeccmtvjaeon/4jQlQnzCwbbUWcLI9SVDiE/8432f68f44d7721399021092d0d36dd4/r_ll1TokenBlack.svg'
  const avaxIcon =
    'https://cdnwp-s3.benzinga.com/wp-content/uploads/2021/02/25122900/avalanche-avax-logo.png'
  const l1Icon =
    'https://images.ctfassets.net/gcj8jwzm6086/5KPky47nVRvtHKYV0rQy5X/e0d153df56fd1eac204f58ca5bc3e133/L1-YouTube-Avatar.png'

  const addLL1ToMetamask = useCallback(async () => {
    setIsError({ ...isError, LL1: '' })
    setIsLoadingAction({ ...isLoadingAction, LL1: true })
    try {
      await addTokenToMetamask(LL1_ADDRESS, ll1andRl1Icon)
      setIsLoadingAction({ ...isLoadingAction, LL1: false })
      setAdded(prev => ({
        ...prev,
        LL1: true,
      }))
    } catch (err: any) {
      setIsLoadingAction({ ...isLoadingAction, LL1: false })
      setIsError({ ...isError, LL1: err.message })
      setTimeout(() => {
        setIsError({ ...isError, LL1: '' })
      }, ERROR_DISPLAY_TIME)
    }
  }, [addTokenToMetamask])

  const addRL1ToMetamask = useCallback(async () => {
    setIsError({ ...isError, RL1: '' })
    setIsLoadingAction({ ...isLoadingAction, RL1: true })
    try {
      await addTokenToMetamask(RL1_ADDRESS, ll1andRl1Icon)
      setIsLoadingAction({ ...isLoadingAction, RL1: false })
      setAdded(prev => ({
        ...prev,
        RL1: true,
      }))
    } catch (err: any) {
      setIsLoadingAction({ ...isLoadingAction, RL1: false })
      setIsError({ ...isError, RL1: err.message })
      setTimeout(() => {
        setIsError({ ...isError, RL1: '' })
      }, ERROR_DISPLAY_TIME)
    }
  }, [addTokenToMetamask])

  const addStakeToMetamask = useCallback(async () => {
    setIsError({ ...isError, STAKE: '' })
    setIsLoadingAction({ ...isLoadingAction, STAKE: true })
    try {
      await addTokenToMetamask(STAKE_ADDRESS, ll1andRl1Icon)
      setIsLoadingAction({ ...isLoadingAction, STAKE: false })
      setAdded(prev => ({
        ...prev,
        STAKE: true,
      }))
    } catch (err: any) {
      setIsLoadingAction({ ...isLoadingAction, STAKE: false })
      setIsError({ ...isError, STAKE: err.message })
      setTimeout(() => {
        setIsError({ ...isError, STAKE: '' })
      }, ERROR_DISPLAY_TIME)
    }
  }, [addTokenToMetamask])

  const addAvaxToMetamask = useCallback(async () => {
    setIsError({ ...isError, AVAX: '' })
    setIsLoadingAction({ ...isLoadingAction, AVAX: true })
    try {
      await addTokenToMetamask(BRIDGE_AVAX_ERC20_REMOTE_ADDRESS, avaxIcon)
      setIsLoadingAction({ ...isLoadingAction, AVAX: false })
      setAdded(prev => ({
        ...prev,
        AVAX: true,
      }))
    } catch (err: any) {
      setIsLoadingAction({ ...isLoadingAction, AVAX: false })
      setIsError({ ...isError, AVAX: err.message })
      setTimeout(() => {
        setIsError({ ...isError, AVAX: '' })
      }, ERROR_DISPLAY_TIME)
    }
  }, [addTokenToMetamask])

  const addAllToMetamask = useCallback(async () => {
    setIsLoadingAction({ ...isLoadingAction, all: true })
    setIsError({ ...isError, all: '' })
    try {
      if (hasBridgeAvax) {
        await addTokenToMetamask(BRIDGE_AVAX_ERC20_REMOTE_ADDRESS, avaxIcon)
      }
      if (hasLL1) {
        await addTokenToMetamask(LL1_ADDRESS, ll1andRl1Icon)
      }
      if (hasStaking) {
        await addTokenToMetamask(RL1_ADDRESS, ll1andRl1Icon)
        await addTokenToMetamask(STAKE_ADDRESS, ll1andRl1Icon)
      }
      setIsLoadingAction({ ...isLoadingAction, all: false })
      setAdded({ RL1: true, LL1: true, STAKE: true, AVAX: true, all: true, L1: false })
    } catch (err: any) {
      setIsLoadingAction({ ...isLoadingAction, all: false })
      setIsError({ ...isError, all: err.message })
      setTimeout(() => {
        setIsError({ ...isError, all: '' })
      }, ERROR_DISPLAY_TIME)
    }
  }, [addTokenToMetamask, hasLL1, hasStaking, hasBridgeAvax])

  // L1 on avalanche
  const addL1ToMetamask = useCallback(async () => {
    setIsError({ ...isError, L1: '' })
    setIsLoadingAction({ ...isLoadingAction, L1: true })
    try {
      await addTokenToMetamaskAvax(BRIDGE_L1_ERC20_REMOTE_ADDRESS, l1Icon)
      setIsLoadingAction({ ...isLoadingAction, L1: false })
      setAdded(prev => ({
        ...prev,
        L1: true,
      }))
    } catch (err: any) {
      setIsLoadingAction({ ...isLoadingAction, L1: false })
      setIsError({ ...isError, L1: err.message })
      setTimeout(() => {
        setIsError({ ...isError, L1: '' })
      }, ERROR_DISPLAY_TIME)
    }
  }, [addTokenToMetamaskAvax])

  const disableButtons = useCallback(
    () =>
      isLoadingAction.RL1 ||
      isLoadingAction.LL1 ||
      isLoadingAction.STAKE ||
      isLoadingAction.AVAX ||
      isLoadingAction.all ||
      isLoadingAction.L1,
    [isLoadingAction]
  )

  const errorMessageDisplayed = useMemo(
    () => isError.all || isError.RL1 || isError.LL1 || isError.STAKE || isError.AVAX || isError.L1,
    [isError]
  )

  return (
    <ModalSkeleton
      opened
      onClose={close}
      title={t('components.wallet.addTokenToMetamask.title', 'Add Tokens to MetaMask')}
    >
      <Stack style={{ gap: '15px', paddingBottom: '30px' }}>
        <Text>
          {
            t(
              'components.wallet.addTokenToMetamask.description',
              'Easily add supported ERC-20 tokens to your MetaMask wallet to always see your LAMINA1 balances. '
            ) as string
          }
        </Text>
        {network === 'Lamina1' && (
          <>
            <AddToken
              addCall={addAllToMetamask}
              token="all"
              added={added.all}
              isLoadingAction={isLoadingAction.all}
              isError={isError.all}
              disabled={disableButtons()}
            />
            {hasBridgeAvax && (
              <AddToken
                addCall={addAvaxToMetamask}
                token="AVAX"
                added={added.AVAX}
                isLoadingAction={isLoadingAction.AVAX}
                isError={isError.AVAX}
                disabled={disableButtons()}
              />
            )}
            {hasLL1 && (
              <AddToken
                addCall={addLL1ToMetamask}
                token="LL1"
                added={added.LL1}
                isLoadingAction={isLoadingAction.LL1}
                isError={isError.LL1}
                disabled={disableButtons()}
              />
            )}
            {hasStaking && (
              <AddToken
                addCall={addRL1ToMetamask}
                token="RL1"
                added={added.RL1}
                isLoadingAction={isLoadingAction.RL1}
                isError={isError.RL1}
                disabled={disableButtons()}
              />
            )}
            {hasStaking && (
              <AddToken
                addCall={addStakeToMetamask}
                token="STAKE"
                added={added.STAKE}
                isLoadingAction={isLoadingAction.STAKE}
                isError={isError.STAKE}
                disabled={disableButtons()}
              />
            )}
          </>
        )}
        {network === 'Avalanche' && (
          <AddToken
            addCall={addL1ToMetamask}
            token="L1"
            added={added.L1}
            isLoadingAction={isLoadingAction.L1}
            isError={isError.L1}
            disabled={disableButtons()}
          />
        )}
        {errorMessageDisplayed && (
          <Text
            style={{
              color: theme.colors.notification[0],
            }}
          >
            {errorMessageDisplayed}
          </Text>
        )}
      </Stack>
    </ModalSkeleton>
  )
}

export default AddToMetamaskModal
