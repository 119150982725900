import { gql } from '@apollo/client'

import { CollectionWithTotal, TokenBase, TotalOfItems } from './types'

// Search Filters

export const suggestionsLimit = 20

// Default Creator Filters (for all users)
const userFilters = `
  { flagged: { _eq: false } }
  { is_contract: { _eq: false } }
  { name: { _neq: "" } }
`
// Creators
const creatorFilters = `
  { avatar: { _neq: "" } }
  { display_name: { _neq: "" } }
  { tokens_aggregate: { count: { predicate: { _gt: 0 } } } }
`

// Creators
export const creatorFiltersAnd = {
  _and: [
    { avatar: { _neq: '' } },
    { display_name: { _neq: '' } },
    { tokens_aggregate: { count: { predicate: { _gt: 0 } } } },
  ],
}

export const creatorSearch = (searchString: string) => {
  if (searchString.startsWith('@')) {
    return { name: { _iregex: searchString.slice(1) } }
  }
  return {
    _or: [
      { bio: { _iregex: searchString } },
      { name: { _iregex: searchString } },
      { display_name: { _iregex: searchString } },
    ],
  }
}

export const creatorFilter = (filteredBy: string) => {
  switch (filteredBy) {
    case 'All Users':
      return {}
    default:
      return creatorFiltersAnd
  }
}

export const creatorSort = (sortedBy: string, direction: 'asc' | 'desc') => {
  switch (sortedBy) {
    // TODO: Add options
    case 'join date':
      return { created_at: direction }
    default:
      return { tokens_aggregate: { count: direction } }
  }
}

export const creatorSuggestions = (searchString: string) => ({
  name: { _iregex: searchString },
})

// Items

export const baseItemFilters = `
  { flagged: { _eq: false } }
  { collection: { collection_type: { _neq: "ERC20" } } }
`

export const publishedItemsFilter = `
  { amount: { _gt: 0 } }
`

export const onlyItemsWithMetadata = `
  { metadata: { _is_null: false } }
`
export const itemFilters = `
  ${baseItemFilters}
  ${publishedItemsFilter}
  ${onlyItemsWithMetadata}
`

export const itemSearch = (searchString: string) => {
  if (searchString.startsWith('#')) {
    return {
      metadata: {
        _cast: {
          String: {
            _iregex: `"tags":\\s*\\[(?:[^\\]]*,)*\\s*"${searchString.slice(1)}"(?:,|\\s*\\])`,
          },
        },
      },
    }
  }
  return {
    metadata: { _cast: { String: { _iregex: searchString } } },
  }
}

export const itemFilter = (filteredBy: string) => {
  switch (filteredBy) {
    case 'Fungible':
      return { amount: { _gt: '1' } }
    case 'Unique':
      return { amount: { _eq: '1' } }
    default:
      return {}
  }
}

export const itemSort = (sortedBy: string, direction: 'asc' | 'desc') => {
  switch (sortedBy) {
    // TODO: Add options
    case 'publish date':
      return { created_at: direction }
    default:
      return { transfers_aggregate: { count: direction } }
  }
}

export const itemSuggestions = (searchString: string) => {
  if (searchString.startsWith('#')) {
    return {
      metadata: {
        _cast: {
          String: {
            _iregex: `"tags":\\s*\\[(?:[^\\]]*,)*\\s*"${searchString.slice(1)}"(?:,|\\s*\\])`,
          },
        },
      },
    }
  }
  return {
    metadata: { _cast: { String: { _iregex: searchString } } },
  }
}

// Collections
const collectionFilters = `
  { flagged: { _eq: false } }
  { collection_type: { _neq: "ERC20" } }
  {
    tokens_aggregate: {
      count: {
        predicate: { _gt: 0 },
        filter: { _and: [{ metadata: { _is_null: false } }, { _and: [${itemFilters}] }] },
      },
    }
  }
`

export const collectionSearch = (searchString: string) => ({
  _or: [{ name: { _iregex: searchString } }, { id: { _iregex: searchString } }],
})

export const collectionFilter = (filteredBy: string) => {
  switch (filteredBy) {
    case 'ERC721':
      return { collection_type: { _eq: 'ERC721' } }
    case 'ERC1155':
      return { collection_type: { _eq: 'ERC1155' } }
    default:
      return {}
  }
}

export const collectionSort = (sortedBy: string, direction: 'asc' | 'desc') => {
  switch (sortedBy) {
    // TODO: Add options
    case 'publish date':
      return { created_at: direction }
    default:
      return { tokens_aggregate: { count: direction } }
  }
}

export const collectionSuggestions = (searchString: string) => ({
  name: { _iregex: searchString },
})

// Totals of Creators, Items and Collections

export type GetTotals = {
  users: {
    agg: {
      count: number
    }
  }
  items: {
    agg: {
      count: number
    }
  }
  collections: {
    agg: {
      count: number
    }
  }
}

export const QUERY_GET_TOTALS = gql`
  query GetTotals {
    users: user_aggregate(
      where: {
        _and: [
          ${userFilters}
          ${creatorFilters}
        ]
      }
    ) {
      agg: aggregate {
        count
      }
    }
    items: token_aggregate(
      where: {
        _and: [
          ${itemFilters}
        ]
      }
    ) {
      agg: aggregate {
        count
      }
    }
    collections: collection_aggregate(
      where: {
        _and: [
          ${collectionFilters}
        ]
      }
    ) {
      agg: aggregate {
        count
      }
    }
  }
`

// Get/Search Creators

export type GetCreators = {
  user: {
    id: string
  }[]
} & TotalOfItems

export const QUERY_GET_CREATORS = gql`
  query GetCreators(
    $limit: Int,
    $offset: Int,
    $search: user_bool_exp!,
    $filter: user_bool_exp!,
    $order: user_order_by!
  ) {
    user(
      where: {
        _and: [
          ${userFilters}
          $search
          $filter
        ]
      }
      order_by: [$order, {id: asc}]
      limit: $limit
      offset: $offset
    ) {
      id
    }
    total: user_aggregate(
      where: {
        _and: [
          ${userFilters}
          $search
          $filter
        ]
      }
    ) {
      agg: aggregate {
        count
      }
    }
  }
`

// Suggested creators

export interface GetSuggestedCreators {
  user: {
    name: string
  }[]
}

export const QUERY_GET_SUGGESTED_CREATORS = gql`
  query GetSuggestedCreators(
    $search: user_bool_exp!,
    $filter: user_bool_exp!,
    $order: user_order_by!
  ) {
    user(
      where: {
        _and: [
          ${userFilters}
          $search
          $filter
        ]
      },
      order_by: [$order, {id: asc}],
      limit: ${suggestionsLimit}
    ) {
      name
    }
  }
`

// Get/Search Items
export type GetItems = {
  token: TokenBase[]
} & TotalOfItems

export const QUERY_GET_ITEMS = gql`
  query GetItems(
    $limit: Int,
    $offset: Int,
    $search: token_bool_exp!,
    $filter: token_bool_exp!,
    $order: token_order_by!
  ) {
    token(
      where: {
        _and: [
          ${itemFilters}
          $search
          $filter
        ]
      }
      order_by: [$order, { id: asc }]
      limit: $limit
      offset: $offset
    ) {
      id
      nativeId: native_id
      creator: creator_id
      uri
      amount
      metadata
      createdAt: created_at
      createdAtBlock: created_at_block
    }
    total: token_aggregate(
      where: {
        _and: [
          ${itemFilters}
          $search
          $filter
        ]
      }
    ) {
      agg: aggregate {
        count
      }
    }
  }
`

// Suggested Items

export interface GetSuggestedItems {
  token: {
    id: string
    nativeId: string
    metadata: any
  }[]
}

export const QUERY_GET_SUGGESTED_ITEMS = gql`
  query GetSuggestedItems(
    $search: token_bool_exp!,
    $filter: token_bool_exp!,
    $order: token_order_by!
  ) {
    token(
      where: {
        _and: [
          ${itemFilters}
          $search
          $filter
        ]
      }
      order_by: [$order, { id: asc }]
      limit: ${suggestionsLimit}
    ) {
      id
      nativeId: native_id
      metadata
    }
  }
`

// Get/Search Collections

export type GetCollections = {
  collection: CollectionWithTotal[]
} & TotalOfItems

export const QUERY_GET_COLLECTIONS = gql`
  query GetCollections(
    $limit: Int,
    $offset: Int,
    $search: collection_bool_exp!,
    $filter: collection_bool_exp!,
    $order: collection_order_by!
  ) {
    collection(
      where: {
        _and: [
          ${collectionFilters}
          $search
          $filter
        ]
      }
      order_by: [$order, { id: asc }]
      limit: $limit
      offset: $offset
    ) {
      id
      name
      symbol
      decimals
      collectionType: collection_type
      createdAt: created_at
      createdAtBlock: created_at_block
      creator: creator_id
      total: tokens_aggregate(
        where: {
          _and: [
            ${itemFilters}
          ]
        }
      ) {
        agg: aggregate {
          count
        }
      }
    }
    total: collection_aggregate(
      where: {
        _and: [
          ${collectionFilters}
          $search
          $filter
        ]
      }
    ) {
      agg: aggregate {
        count
      }
    }
  }
`

// Suggested Collections

export interface GetSuggestedCollections {
  collection: {
    id: string
    name: string
  }[]
}

export const QUERY_GET_SUGGESTED_COLLECTIONS = gql`
  query SuggestedCollections(
    $search: collection_bool_exp!,
    $filter: collection_bool_exp!,
    $order: collection_order_by!
  ) {
    collection(
      where: {
        _and: [
          ${collectionFilters}
          $search
          $filter
        ]
      }
      order_by: [$order, { id: asc }]
      limit: ${suggestionsLimit}
    ) {
      id
      name
    }
  }
`
