import { Modal, Stack, Switch, Title } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { erc20Abi, isAddress, parseUnits, TransactionReceipt } from 'viem'

import {
  AVAX_CHAIN_TICKER,
  BLANK_ADDRESS,
  BRIDGE_AVAX_ERC20_REMOTE_ADDRESS,
  BRIDGE_L1_ERC20_REMOTE_ADDRESS,
  DEFAULT_ADDRESS_GAS_LIMIT,
  DEFAULT_AMOUNT_GAS_LIMIT,
  DEFAULT_ERC20_TRANSFER_GAS_LIMIT,
  NATIVE_CHAIN_TICKER,
} from '@/constants/blockchain'
import { useChainFeaturesContext } from '@/contexts/ChainFeaturesContext'
import useBridge from '@/hooks/useBridge'
import { ContractCall, useChainFunctions } from '@/hooks/useChain'
import useEstimatedFee from '@/hooks/useEstimatedFee'
import useL1NS from '@/hooks/useL1NS'
import { useAuth } from '@/plugins/auth'
import {
  avalancheConfig,
  avalancheWagmiConfig,
  l1NativeConfig,
  l1NativeWagmiConfig,
} from '@/plugins/auth/config'
import { NetworkOptions, networkOptions } from '@/utils/utils'

import RootButton from '../Buttons/RootButton'
import SendDropdownButton from '../Buttons/SendDropdownButton'
import CurrencyInput from '../CurrencyInput'
import ErrorMessage from '../ErrorMessage'
import { checkInputHandleAddress } from '../Identity/rules'
import Loading from '../Loading'
import ModalSkeleton from '../Modals/ModalSkeleton'
import ConfirmTransaction from '../Modals/Send/ConfirmTransaction'
import { AtIcon, CheckedIcon, InvalidIcon, SpecialInputText } from '../SpecialInputText'
import OnTransfer from './OnTransfer'
import PayBridgeFee from './PayBridgeFee'
import classes from './Send.module.css'
import TransactionSummary from './TransactionSummary'

interface SendProps {
  close: () => void
  setOkToClose: (okToClose: boolean) => void
}

// Time to wait before checking username
export const DEBOUNCE_USERNAME_RESOLVE_TIME = 1000

const isBridgeable = (
  origin: NetworkOptions,
  token: string,
  hasBridgeL1: boolean,
  hasBridgeAvax: boolean
) => {
  if (origin === networkOptions.l1) {
    return token === NATIVE_CHAIN_TICKER ? hasBridgeL1 : hasBridgeAvax
  }
  return (
    origin === networkOptions.avax && (token === AVAX_CHAIN_TICKER ? hasBridgeAvax : hasBridgeL1)
  )
}

const erc20TransferCall = (address: string, destination: string, amount: string): ContractCall => ({
  args: {
    address: address as `0x${string}`,
    abi: erc20Abi,
    functionName: 'transfer',
    args: [destination as `0x${string}`, parseUnits(amount, 18)],
  },
  fallbackGas: DEFAULT_ERC20_TRANSFER_GAS_LIMIT,
})

const Send: React.FC<SendProps> = ({ close, setOkToClose }) => {
  const { t } = useTranslation()
  // Set up network dropdown options and translations. Since i18next.t() do not allow for keys to be non String Literal Types, we have to manually initialize this array and rewrite the keys as string literals.
  const networkDropdownOptions: NetworkOptions[] = [networkOptions.l1, networkOptions.avax]

  const { address } = useAuth()
  const { hasFeature } = useChainFeaturesContext()
  const hasBridgeL1 = hasFeature('bridge-l1')
  const hasBridgeAvax = hasFeature('bridge-avax')

  // Logged In Info
  const isNotMetamask = localStorage.getItem('Web3Auth-cachedAdapter') !== 'metamask'

  // Network Switch
  const [networkOrigin, setNetworkOrigin] = useState<NetworkOptions>(networkOptions.l1)
  const [networkDestination, setNetworkDestination] = useState<NetworkOptions>(networkOptions.l1)
  const [token, setToken] = useState<string>(NATIVE_CHAIN_TICKER)

  // Cross Chain Transaction Summary
  const crossChain = useMemo(
    () => ({
      token,
      networkOrigin,
      networkDestination,
    }),
    [token, networkOrigin, networkDestination]
  )

  // Can this token be bridged?
  const tokenBridgeable = useMemo(
    () => isBridgeable(networkOrigin, token, hasBridgeL1, hasBridgeAvax),
    [token, hasBridgeL1, hasBridgeAvax, networkOrigin]
  )

  // send to myself switch
  const [sendToMyself, setSendToMyself] = useState<boolean>(false)

  const { sendTransfer, writeContracts } = useChainFunctions(l1NativeWagmiConfig)
  const { sendTransfer: sendTransferAvax, writeContracts: writeContractsAvax } =
    useChainFunctions(avalancheWagmiConfig)

  // Bridge calls
  const { nativeL1ToAvax, erc20AvaxToAvax, erc20L1ToLamina1, nativeAvaxToLamina1 } = useBridge()

  // Modal
  const [openedConfirmTx, { open: openConfirmTx, close: closeConfirmTx }] = useDisclosure(false)

  const [destinationAddress, setDestinationAddress] = useState({
    value: '',
    touched: false,
    error: '',
  })
  // const [recipientAddress, setRecipientAddress] = useState<string | null>(null)
  const recipientAddressRef = useRef<string | null>(null)
  const [amount, setAmount] = useState({ value: '', touched: false, error: '' })
  const [txHash, setTxHash] = useState<string | null>(null)
  const [sendingTx, setSendingTx] = useState<boolean | null>(null)

  // contract calls being executed
  const [contractCalls, setContractCalls] = useState<ContractCall[]>([])

  // fees
  const { estimatedFee } = useEstimatedFee(networkOrigin, contractCalls)

  // handle vs address
  const { getL1nsName, getL1nsAddress } = useL1NS()
  const [validSendAddress, setValidSendAddress] = useState<boolean>(false)
  const [isHandle, setIsHandle] = useState<boolean>(false)
  const [handleCheckStatus, setHandleCheckStatus] = useState({
    input: '',
    message: '',
    verified: false,
  })

  // send transaction error
  const [error, setError] = useState<string>('')
  const [openedError, { open: openError, close: closeError }] = useDisclosure(false)

  /* -------------------------------------------------------------------------- */
  /*     Memoized Variables Dependent on User Input (network, token, balance)   */
  /* -------------------------------------------------------------------------- */
  const withToggleToMyself = useMemo(
    () => networkOrigin !== networkDestination,
    [networkOrigin, networkDestination]
  )

  const readyToSend = useMemo(
    () =>
      !(
        sendingTx ||
        !destinationAddress.touched ||
        !amount.touched ||
        destinationAddress.error !== '' ||
        amount.error !== '' ||
        !validSendAddress
      ),
    [sendingTx, destinationAddress, amount, validSendAddress]
  )

  /* -------------------------------------------------------------------------- */
  /*            Contract calls based on network route and token                 */
  /* -------------------------------------------------------------------------- */
  useEffect(() => {
    const updateCalls = async () => {
      let calls: ContractCall[] = []
      if (networkOrigin !== networkDestination) {
        if (networkOrigin === networkOptions.l1) {
          // if source is Lamina1 (L1) and destination is Avalanche (L1 ERC20)
          if (token === NATIVE_CHAIN_TICKER) {
            calls = nativeL1ToAvax(DEFAULT_ADDRESS_GAS_LIMIT, DEFAULT_AMOUNT_GAS_LIMIT)
          }
          // if source is Lamina1 (AVAX ERC20) and destination is Avalanche (AVAX)
          if (token === AVAX_CHAIN_TICKER) {
            calls = await erc20AvaxToAvax(DEFAULT_ADDRESS_GAS_LIMIT, DEFAULT_AMOUNT_GAS_LIMIT)
          }
        } else {
          // if source is Avalanche (L1 ERC20) and destination is Lamina1 (L1)
          if (token === NATIVE_CHAIN_TICKER) {
            calls = await erc20L1ToLamina1(DEFAULT_ADDRESS_GAS_LIMIT, DEFAULT_AMOUNT_GAS_LIMIT)
          }
          // if source is Avalanche (AVAX) and destination is Lamina1 (AVAX ERC20)
          if (token === AVAX_CHAIN_TICKER) {
            calls = nativeAvaxToLamina1(DEFAULT_ADDRESS_GAS_LIMIT, DEFAULT_AMOUNT_GAS_LIMIT)
          }
        }
      } else if (networkOrigin === networkOptions.l1 && token !== NATIVE_CHAIN_TICKER) {
        // AVAX ERC20 on L1
        calls = [
          erc20TransferCall(
            BRIDGE_AVAX_ERC20_REMOTE_ADDRESS,
            DEFAULT_ADDRESS_GAS_LIMIT,
            DEFAULT_AMOUNT_GAS_LIMIT
          ),
        ]
      } else if (networkOrigin === networkOptions.avax && token !== AVAX_CHAIN_TICKER) {
        // L1 ERC20 on AVAX
        calls = [
          erc20TransferCall(
            BRIDGE_L1_ERC20_REMOTE_ADDRESS,
            DEFAULT_ADDRESS_GAS_LIMIT,
            DEFAULT_AMOUNT_GAS_LIMIT
          ),
        ]
      }
      setContractCalls(calls)
    }
    updateCalls()
  }, [networkOrigin, networkDestination, token])

  /* -------------------------------------------------------------------------- */
  /*                             Relayer/Bridge Fee                             */
  /* -------------------------------------------------------------------------- */
  // Pay Relayer AVAX Fee
  const payRelayer = useMemo(
    () => networkOrigin === networkOptions.l1 && networkDestination === networkOptions.avax,
    [networkOrigin, networkDestination]
  )
  // Relayer Fee Paid Status
  const relayerFeePaid = useRef<boolean | undefined>(undefined)
  const relayerFeeLoading = useRef<boolean>(false)
  // Lock Transaction Params on Relayer Fee Payment
  const txParamsLocked = useMemo(() => relayerFeePaid.current, [relayerFeePaid.current])
  const txSent = useRef<boolean>(false)

  // Update okToClose state
  const updateOkToClose = useCallback(() => {
    // if relayer fee does not have to be paid, allow user to close modal
    if (relayerFeePaid.current === undefined) {
      setOkToClose(true)
      return
    }
    // if relayer fee has been paid, allow user to close modal only if transaction is sent
    if (relayerFeePaid.current) {
      setOkToClose(txSent.current)
      return
    }
    // if none of the above (relayer fee not paid && tx not sent), do not allow user to close modal
    setOkToClose(false)
  }, [relayerFeePaid, txSent])

  /* -------------------------------------------------------------------------- */
  /*                        On Send: Confirm Transaction                        */
  /* -------------------------------------------------------------------------- */

  // Send Transaction Function: handle loading state, error handling, and transaction receipt
  const sendTransaction = useCallback(async () => {
    if (!recipientAddressRef.current) {
      setTxHash(null)
      return
    }
    setSendingTx(true)
    try {
      let receipt: string | TransactionReceipt | null = null
      // cross-chain transaction
      if (networkOrigin !== networkDestination) {
        if (networkOrigin === networkOptions.l1) {
          // if source is Lamina1 (L1) and destination is Avalanche (L1 ERC20)
          if (token === NATIVE_CHAIN_TICKER) {
            const calls = nativeL1ToAvax(recipientAddressRef.current, amount.value)
            receipt = await writeContracts(calls)
          }
          // if source is Lamina1 (AVAX ERC20) and destination is Avalanche (AVAX)
          if (token === AVAX_CHAIN_TICKER) {
            const calls = await erc20AvaxToAvax(recipientAddressRef.current, amount.value)
            receipt = await writeContracts(calls)
          }
        } else {
          // if source is Avalanche (L1 ERC20) and destination is Lamina1 (L1)
          if (token === NATIVE_CHAIN_TICKER) {
            const calls = await erc20L1ToLamina1(recipientAddressRef.current, amount.value)
            receipt = await writeContractsAvax(calls)
          }
          // if source is Avalanche (AVAX) and destination is Lamina1 (AVAX ERC20)
          if (token === AVAX_CHAIN_TICKER) {
            const calls = nativeAvaxToLamina1(recipientAddressRef.current, amount.value)
            receipt = await writeContractsAvax(calls)
          }
        }
      } else if (networkOrigin === networkOptions.l1) {
        // If sending L1 on L1, native, otherwise AVAX ERC20
        if (token === NATIVE_CHAIN_TICKER) {
          receipt = await sendTransfer(recipientAddressRef.current, amount.value)
        } else {
          const call = [
            erc20TransferCall(
              BRIDGE_AVAX_ERC20_REMOTE_ADDRESS,
              recipientAddressRef.current,
              amount.value
            ),
          ]
          receipt = await writeContracts(call)
        }
      } else if (token === AVAX_CHAIN_TICKER) {
        // If sending AVAX on AVAX, native, otherwise L1 ERC20
        receipt = await sendTransferAvax(recipientAddressRef.current, amount.value)
      } else {
        const call = [
          erc20TransferCall(
            BRIDGE_L1_ERC20_REMOTE_ADDRESS,
            recipientAddressRef.current,
            amount.value
          ),
        ]
        receipt = await writeContractsAvax(call)
      }
      if (receipt && typeof receipt !== 'string') {
        setTxHash((receipt as TransactionReceipt).transactionHash)
      } else if (receipt) {
        setTxHash(receipt)
      }
    } catch (err: any) {
      setSendingTx(false)
      if (!err.message.includes('User rejected the request.')) {
        setError(err.message)
        openError()
      }
    }
  }, [
    amount.value,
    networkOrigin,
    networkDestination,
    token,
    nativeL1ToAvax,
    erc20AvaxToAvax,
    erc20L1ToLamina1,
    nativeAvaxToLamina1,
    sendTransfer,
    sendTransferAvax,
    writeContracts,
    writeContractsAvax,
  ])

  const resetDestinationHandle = useCallback(() => {
    setHandleCheckStatus({
      input: '',
      message: '',
      verified: false,
    })
    setDestinationAddress({
      value: '',
      touched: false,
      error: '',
    })
    setValidSendAddress(false)
  }, [setHandleCheckStatus, setDestinationAddress])

  // Handle Username/Address Validation:
  // check if username exists, set recipient address, and validate send address
  const handleCheckStatusAction = useCallback(async () => {
    if (isHandle) {
      const reverseAddress = await getL1nsAddress(destinationAddress.value)
      if (reverseAddress && reverseAddress !== BLANK_ADDRESS) {
        setHandleCheckStatus({
          input: destinationAddress.value,
          message: `${reverseAddress}`,
          verified: true,
        })
        recipientAddressRef.current = reverseAddress
        setValidSendAddress(true)
      } else {
        setHandleCheckStatus({
          input: destinationAddress.value,
          message: '',
          verified: true,
        })
        setValidSendAddress(false)
        setDestinationAddress({
          value: destinationAddress.value,
          touched: true,
          error: 'Username not found',
        })
      }
    } else {
      const reverseName = await getL1nsName(destinationAddress.value)
      if (reverseName) {
        setHandleCheckStatus({
          input: destinationAddress.value,
          message: `${reverseName}`,
          verified: true,
        })
      } else {
        setHandleCheckStatus({
          input: destinationAddress.value,
          message: '',
          verified: true,
        })
      }
      recipientAddressRef.current = destinationAddress.value
      setValidSendAddress(true)
    }
  }, [destinationAddress.value, getL1nsAddress, getL1nsName, isHandle, sendTransaction])

  // Handle transaction submission:
  // validate username, send transaction (dependent on token), error handling
  const submitTransaction = useCallback(async () => {
    try {
      // TODO: change function call to depend on network and token
      await sendTransaction()
      txSent.current = true
      updateOkToClose()
    } catch (err: any) {
      console.error('An error occurred:', err)
      if (!err.message.includes('User rejected the request.')) {
        setError(err.message)
        openError()
      }
    }
  }, [sendTransaction, setError, openError])

  // Push transaction on send button click
  const pushTransaction = useCallback(async () => {
    // If user is logged in through Web3Auth, open the confirm transaction modal
    if (isNotMetamask) {
      openConfirmTx()
    } else {
      await submitTransaction()
    }
  }, [openConfirmTx, submitTransaction])

  // Modal confirmation for Web3Auth
  const confirm = useCallback(async () => {
    closeConfirmTx()
    await submitTransaction()
  }, [closeConfirmTx, submitTransaction])

  /* -------------------------------------------------------------------------- */
  /*                             Handle Input Fields                            */
  /* -------------------------------------------------------------------------- */
  const handleDestinationChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => {
      const { value } = e.target
      resetDestinationHandle()
      setIsHandle(!isAddress(value))
      const destinationError = checkInputHandleAddress(t, value, !isAddress(value))
      setDestinationAddress({
        value: value.toLowerCase(),
        touched: true,
        error: destinationError,
      })
      setValidSendAddress(false)
      setHandleCheckStatus({
        input: value.toLowerCase(),
        message: '',
        verified: false,
      })
      setSendingTx(null)
    },
    [t]
  )

  // Resolve username from destination changes with debounce
  useEffect(() => {
    const handler = setTimeout(() => {
      if (destinationAddress.touched && destinationAddress.error === '') {
        handleCheckStatusAction()
      }
    }, DEBOUNCE_USERNAME_RESOLVE_TIME)

    return () => {
      clearTimeout(handler)
    }
  }, [destinationAddress])

  /* -------------------------------------------------------------------------- */
  /*                              Input Field Icons                             */
  /* -------------------------------------------------------------------------- */

  // Left Icon showing validation status
  const iconLeft = useMemo(
    () =>
      handleCheckStatus.verified ? (
        validSendAddress ? (
          <CheckedIcon />
        ) : (
          <InvalidIcon />
        )
      ) : (
        <AtIcon />
      ),
    [validSendAddress, handleCheckStatus]
  )

  /* -------------------------------------------------------------------------- */
  /*                               Network Change                               */
  /* -------------------------------------------------------------------------- */
  // Handle dropdown menu change for origin network
  const handleNetworkOriginChange = useCallback(
    (selectedOption: NetworkOptions) => {
      setSendToMyself(false)
      resetDestinationHandle()

      setNetworkOrigin(selectedOption)
      // Default to same network send, simplifies supported bridges logic
      setNetworkDestination(selectedOption)
      // Default to network native token, simplifies supported bridges logic
      setToken(selectedOption === networkOptions.l1 ? NATIVE_CHAIN_TICKER : AVAX_CHAIN_TICKER)
    },
    [networkOrigin, setNetworkOrigin]
  )

  // Handle dropdown menu change for destination network
  const handleNetworkDestinationChange = useCallback(
    (selectedOption: NetworkOptions) => {
      setNetworkDestination(selectedOption)
      setSendToMyself(false)
    },
    [setNetworkDestination, setSendToMyself]
  )

  // When token is changed, set network destination to same as origin if bridging not supported
  const handleTokenChange = useCallback(
    (selectedToken: string) => {
      setToken(selectedToken)
      if (!isBridgeable(networkOrigin, selectedToken, hasBridgeL1, hasBridgeAvax)) {
        setNetworkDestination(networkOrigin)
      }
    },
    [hasBridgeL1, hasBridgeAvax, networkOrigin, setToken]
  )

  /* -------------------------------------------------------------------------- */
  /*                       Logic: Process State Variables                       */
  /* -------------------------------------------------------------------------- */

  const handleSendToMyselfToogle = useCallback(() => {
    if (!address) return

    // Given a state variable will not be updated immediately, we need to use the current value to update the remaining fucntion logic
    const currSendToMyself = !sendToMyself
    setSendToMyself(prev => !prev)

    // If send to myself is toggled, set the destination address to the user's address
    if (currSendToMyself) {
      setIsHandle(false)
      setDestinationAddress({
        value: address,
        touched: true,
        error: '',
      })
      setValidSendAddress(true)
    } else {
      resetDestinationHandle()
    }
  }, [sendToMyself, address])

  /* -------------------------------------------------------------------------- */
  /*                          Transaction Confirmation                          */
  /* -------------------------------------------------------------------------- */
  const handleTransactionConfirmation = useCallback(() => {
    setTxHash(null)
    setSendingTx(false)
    setSendToMyself(false)
    setAmount({ value: '', touched: false, error: '' })
    setDestinationAddress({ value: '', touched: false, error: '' })
    setHandleCheckStatus({ input: '', message: '', verified: false })
    setValidSendAddress(false)
    relayerFeePaid.current = false
    relayerFeeLoading.current = false
    txSent.current = false
  }, [setTxHash, setSendingTx, setAmount, setDestinationAddress, setHandleCheckStatus])

  return (
    <>
      {address && (
        <>
          <ModalSkeleton
            opened={openedConfirmTx}
            onClose={closeConfirmTx}
            size="420px"
            title={t('components.send.confirmTransaction', 'Confirm Transaction')}
          >
            <ConfirmTransaction close={closeConfirmTx} confirm={confirm} />
          </ModalSkeleton>
          <ModalSkeleton opened={openedError} onClose={closeError} size="420px">
            <Modal.CloseButton onClick={closeError} />
            <ErrorMessage
              message={t('components.send.error', 'Error sending transaction')}
              details={error}
            />
          </ModalSkeleton>
          {sendingTx ? (
            <Stack>
              {txHash ? (
                <OnTransfer
                  hash={txHash}
                  chainId={
                    networkOrigin === networkOptions.avax
                      ? avalancheConfig.chainId
                      : l1NativeConfig.chainId
                  }
                  success={handleTransactionConfirmation}
                  onClose={close}
                  successMessage={t('buttons.sendMore', 'Send More')}
                />
              ) : (
                <Loading title={t('components.send.sendingTx', 'Sending Transaction')} />
              )}
            </Stack>
          ) : (
            <Stack className={classes.tileForm}>
              <Stack style={{ marginTop: 20 }}>
                <Stack style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                  <Title className={classes.headers}>{t('components.send.amount', 'Amount')}</Title>
                  <SendDropdownButton
                    options={networkDropdownOptions}
                    selectedOption={networkOrigin}
                    setSelectedOption={handleNetworkOriginChange}
                    disabled={
                      !(hasBridgeL1 || hasBridgeAvax) ||
                      openedConfirmTx ||
                      openedError ||
                      txParamsLocked
                    }
                    label="Origin"
                  />
                </Stack>
                <CurrencyInput
                  amount={amount}
                  setAmount={setAmount}
                  token={token}
                  setToken={handleTokenChange}
                  estimatedFee={estimatedFee}
                  networkOrigin={networkOrigin}
                  disabledDropdown={openedConfirmTx || openedError || txParamsLocked}
                  disabledInput={openedConfirmTx || openedError || txParamsLocked}
                />
              </Stack>
              <Stack style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                <Title className={classes.headers}>{t('components.send.sentTo', 'Send To')}</Title>
                <SendDropdownButton
                  options={networkDropdownOptions}
                  selectedOption={networkDestination}
                  setSelectedOption={handleNetworkDestinationChange}
                  disabled={!tokenBridgeable || openedConfirmTx || openedError || txParamsLocked}
                  label="Destination"
                />
              </Stack>
              {withToggleToMyself && (
                <Switch
                  className={classes.switch}
                  checked={sendToMyself}
                  onChange={handleSendToMyselfToogle}
                  label={t('components.send.myself', 'My Account Address')}
                  readOnly={txParamsLocked}
                />
              )}
              {!sendToMyself && (
                <SpecialInputText
                  disabled={txParamsLocked}
                  placeholder={t(
                    'components.send.placeholderAddressAndUsername',
                    'Enter address or username'
                  )}
                  value={destinationAddress.value}
                  error={
                    destinationAddress.touched && destinationAddress.error !== ''
                      ? destinationAddress.error
                      : null
                  }
                  borderStatus={
                    destinationAddress.touched
                      ? destinationAddress.error
                        ? 'error'
                        : 'success'
                      : 'default'
                  }
                  iconLeft={iconLeft}
                  onChange={handleDestinationChange}
                  tipLeft={t(
                    'components.send.sentToTipAddressAndUsername',
                    'Enter your recipient’s @username or 0x/wallet address'
                  )}
                />
              )}
              <>
                {payRelayer && readyToSend && (
                  <PayBridgeFee
                    relayerFeePaid={relayerFeePaid}
                    relayerFeeLoading={relayerFeeLoading}
                    networkDestination={networkDestination}
                    sendTransferAvax={sendTransferAvax}
                    setSendingTx={setSendingTx}
                    setError={setError}
                    openError={openError}
                    updateOkToClose={updateOkToClose}
                  />
                )}
                <Stack style={{ flexDirection: 'row' }}>
                  <RootButton
                    style1={false}
                    expand
                    secondary
                    onClick={close}
                    disabled={payRelayer && relayerFeeLoading.current}
                  >
                    {t('buttons.cancel', 'Cancel')}
                  </RootButton>
                  <RootButton
                    style1={false}
                    expand
                    disabled={
                      // if needs to pay relayer then check relayer fee status
                      (payRelayer && (!relayerFeePaid.current || relayerFeeLoading.current)) ||
                      // if not paying relayer then check if ready to send
                      !readyToSend ||
                      // if sending transaction
                      openedConfirmTx ||
                      openedError
                    }
                    onClick={pushTransaction}
                  >
                    {t('buttons.send', 'Send')}
                  </RootButton>
                </Stack>
                <TransactionSummary
                  amount={amount.value}
                  contractCalls={contractCalls}
                  crossChain={crossChain}
                />
              </>
            </Stack>
          )}
        </>
      )}
    </>
  )
}
export default Send
