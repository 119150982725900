import { useMemo } from 'react'
import { erc20Abi } from 'viem'
import { useAccount } from 'wagmi'

import {
  BRIDGE_AVAX_ERC20_REMOTE_ADDRESS,
  BRIDGE_L1_ERC20_REMOTE_ADDRESS,
  LL1_ADDRESS,
  RL1_ADDRESS,
  STAKE_ADDRESS,
} from '@/constants/blockchain'
import { useChainFeaturesContext } from '@/contexts/ChainFeaturesContext'
import LL1 from '@/contracts/LL1'
import RL1 from '@/contracts/RL1'
import Stake from '@/contracts/Stake'
import { useCustomContractReadBalance } from '@/hooks/useCustomContract'
import { avalancheConfig, l1NativeConfig, l1NativeWagmiConfig } from '@/plugins/auth/config'
import { CustomError, parseError } from '@/utils/helpers'
import { emptyBalance, processBalance, ProcessedBalance } from '@/utils/utils'

import { useChainValues } from './useChain'

export type BalancesHook = {
  // Native balances
  l1NetworkL1Balance: ProcessedBalance
  avaxNetworkAvaxBalance: ProcessedBalance
  // Bridge ERC20 balances
  bridge: {
    hasBridgeAvax: boolean
    l1NetworkAvaxBalance: ProcessedBalance
    hasBridgeL1: boolean
    avaxNetworkL1Balance: ProcessedBalance
  }
  // LL1 balances
  ll1: {
    hasLL1: boolean
    balance: ProcessedBalance
    withdrawable: ProcessedBalance
    locked: ProcessedBalance
  }
  // RL1 balances
  rl1: {
    hasStaking: boolean
    balance: ProcessedBalance
    withdrawable: ProcessedBalance
    locked: ProcessedBalance
  }
  // Stake balance
  stake: ProcessedBalance
  isLoading: boolean
  error: CustomError
}

export const EmptyBalances: BalancesHook = {
  l1NetworkL1Balance: emptyBalance,
  avaxNetworkAvaxBalance: emptyBalance,
  bridge: {
    hasBridgeAvax: false,
    l1NetworkAvaxBalance: emptyBalance,
    hasBridgeL1: false,
    avaxNetworkL1Balance: emptyBalance,
  },
  ll1: {
    hasLL1: false,
    balance: emptyBalance,
    withdrawable: emptyBalance,
    locked: emptyBalance,
  },
  rl1: {
    hasStaking: false,
    balance: emptyBalance,
    withdrawable: emptyBalance,
    locked: emptyBalance,
  },
  stake: emptyBalance,
  isLoading: false,
  error: {
    call: '',
    msg: undefined,
    isError: false,
  },
}

export const useBalances = (): BalancesHook => {
  const { address } = useAccount()
  const { hasFeature } = useChainFeaturesContext()
  const hasLL1 = hasFeature('ll1')
  const hasStaking = hasFeature('staking')
  const hasBridgeL1 = hasFeature('bridge-l1')
  const hasBridgeAvax = hasFeature('bridge-avax')
  const { blockNumber, balance: l1NetworkL1Balance } = useChainValues(l1NativeConfig.chainId)
  const { blockNumber: blockNumberAvax, balance: avaxNetworkAvaxBalance } = useChainValues(
    avalancheConfig.chainId
  )

  // ERC20 balances
  const {
    value: l1NetworkAvaxBalance,
    isLoading: isLoadingL1NetworkAvaxBalance,
    error: errorL1NetworkAvaxBalance,
  } = useCustomContractReadBalance(
    {
      chainId: l1NativeConfig.chainId,
      address: BRIDGE_AVAX_ERC20_REMOTE_ADDRESS,
      args: [address as `0x${string}`],
      abi: erc20Abi,
      functionName: 'balanceOf',
    },
    blockNumber,
    !!address && hasBridgeAvax
  )

  const {
    value: avaxNetworkL1Balance,
    isLoading: isLoadingAvaxNetworkL1Balance,
    error: errorAvaxNetworkL1Balance,
  } = useCustomContractReadBalance(
    {
      chainId: avalancheConfig.chainId,
      address: BRIDGE_L1_ERC20_REMOTE_ADDRESS,
      args: [address as `0x${string}`],
      abi: erc20Abi,
      functionName: 'balanceOf',
    },
    blockNumberAvax,
    !!address && hasBridgeL1
  )

  // LL1 Balances

  const {
    value: totalLl1,
    isLoading: isLoadingTotalLl1,
    error: errorTotalLl1,
  } = useCustomContractReadBalance(
    {
      chainId: l1NativeConfig.chainId,
      address: LL1_ADDRESS,
      args: [address as `0x${string}`],
      abi: LL1,
      functionName: 'balanceOf',
    },
    blockNumber,
    !!address && hasLL1
  )

  const {
    value: ll1Withdrawable,
    isLoading: isLoadingLl1Widrawable,
    error: errorLl1Withdrawable,
  } = useCustomContractReadBalance(
    {
      chainId: l1NativeConfig.chainId,
      address: LL1_ADDRESS,
      args: [address as `0x${string}`],
      abi: LL1,
      functionName: 'withdrawable',
    },
    blockNumber,
    !!address && hasLL1
  )

  const ll1Locked = useMemo(() => {
    if (totalLl1 && ll1Withdrawable) {
      return processBalance(totalLl1.value - ll1Withdrawable.value)
    }
    return emptyBalance
  }, [totalLl1, ll1Withdrawable])

  // RL1 Balances

  const {
    value: totalRl1,
    isLoading: isLoadingTotalRl1,
    error: errorTotalRl1,
  } = useCustomContractReadBalance(
    {
      chainId: l1NativeConfig.chainId,
      address: RL1_ADDRESS,
      args: [address as `0x${string}`],
      abi: RL1,
      functionName: 'balanceOf',
    },
    blockNumber,
    !!address && hasStaking
  )

  const {
    value: rl1Withdrawable,
    isLoading: isLoadingRl1Withdrawable,
    error: errorRl1Withdrawable,
  } = useCustomContractReadBalance(
    {
      chainId: l1NativeConfig.chainId,
      address: RL1_ADDRESS,
      args: [address as `0x${string}`],
      abi: RL1,
      functionName: 'withdrawable',
    },
    blockNumber,
    !!address && hasStaking
  )

  const rl1Locked = useMemo(() => {
    if (totalRl1 && rl1Withdrawable) {
      return processBalance(totalRl1.value - rl1Withdrawable.value)
    }
    return emptyBalance
  }, [totalRl1, rl1Withdrawable])

  // User stake balance
  const {
    value: stake,
    isLoading: isLoadingStake,
    error: errorStake,
  } = useCustomContractReadBalance(
    {
      chainId: l1NativeWagmiConfig.id,
      address: STAKE_ADDRESS,
      args: [address as `0x${string}`],
      abi: Stake,
      functionName: 'balanceOf',
    },
    blockNumber,
    !!address && hasStaking
  )

  const isLoading = useMemo(
    () =>
      isLoadingL1NetworkAvaxBalance ||
      isLoadingAvaxNetworkL1Balance ||
      isLoadingTotalLl1 ||
      isLoadingLl1Widrawable ||
      isLoadingTotalRl1 ||
      isLoadingRl1Withdrawable ||
      isLoadingStake,
    [
      isLoadingL1NetworkAvaxBalance,
      isLoadingAvaxNetworkL1Balance,
      isLoadingTotalLl1,
      isLoadingLl1Widrawable,
      isLoadingTotalRl1,
      isLoadingRl1Withdrawable,
      isLoadingStake,
    ]
  )

  const error = useMemo(
    () =>
      parseError([
        errorL1NetworkAvaxBalance,
        errorAvaxNetworkL1Balance,
        errorTotalLl1,
        errorLl1Withdrawable,
        errorTotalRl1,
        errorRl1Withdrawable,
        errorStake,
      ]),
    [
      errorL1NetworkAvaxBalance,
      errorAvaxNetworkL1Balance,
      errorTotalLl1,
      errorLl1Withdrawable,
      errorTotalRl1,
      errorRl1Withdrawable,
      errorStake,
    ]
  )

  return {
    l1NetworkL1Balance,
    avaxNetworkAvaxBalance,
    bridge: {
      hasBridgeAvax,
      l1NetworkAvaxBalance,
      hasBridgeL1,
      avaxNetworkL1Balance,
    },
    ll1: {
      hasLL1,
      balance: totalLl1,
      withdrawable: ll1Withdrawable,
      locked: ll1Locked,
    },
    rl1: {
      hasStaking,
      balance: totalRl1,
      withdrawable: rl1Withdrawable,
      locked: rl1Locked,
    },
    stake,
    isLoading,
    error: {
      call: `balances.${error.call}`,
      msg: error.msg,
      isError: error.isError,
    },
  }
}
