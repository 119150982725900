import { useCallback } from 'react'
import { erc20Abi, parseEther, zeroAddress } from 'viem'

import {
  AVAX_CHAIN_BLOCKCHAIN_ID,
  BRIDGE_AVAX_ERC20_REMOTE_ADDRESS,
  BRIDGE_AVAX_NATIVE_HOME_ADDRESS,
  BRIDGE_L1_ERC20_REMOTE_ADDRESS,
  BRIDGE_L1_NATIVE_HOME_ADDRESS,
  DEFAULT_BRIDGE_GAS_LIMIT,
  DEFAULT_ERC20_APPROVE_GAS_LIMIT,
  LAMINA1_CHAIN_BLOCKCHAIN_ID,
} from '@/constants/blockchain'
import IERC20TokenTransferrer from '@/contracts/IERC20TokenTransferrer'
import INativeTokenTransferrer from '@/contracts/INativeTokenTransferrer'
import { ContractCall, useChainFunctions } from '@/hooks/useChain'
import { useAuth } from '@/plugins/auth'
import { avalancheWagmiConfig, l1NativeWagmiConfig } from '@/plugins/auth/config'

const contractCallFromNative = (
  sourceBridge: string,
  destinationChain: string,
  destinationBridge: string,
  destinationAddress: string,
  amount: string,
  feeAddress: string,
  feeAmount: string
): ContractCall => ({
  args: {
    address: sourceBridge as `0x${string}`,
    abi: INativeTokenTransferrer,
    functionName: 'send',
    args: [
      {
        destinationBlockchainID: destinationChain,
        destinationTokenTransferrerAddress: destinationBridge,
        recipient: destinationAddress as `0x${string}`,
        primaryFeeTokenAddress: feeAddress,
        primaryFee: parseEther(feeAmount),
        secondaryFee: BigInt(0),
        requiredGasLimit: 100000,
        multiHopFallback: zeroAddress,
      },
    ],
    value: parseEther(amount),
  },
  fallbackGas: DEFAULT_BRIDGE_GAS_LIMIT,
})

const contractCallFromErc20 = (
  sourceBridge: string,
  destinationChain: string,
  destinationBridge: string,
  destinationAddress: string,
  amount: string,
  feeAddress: string,
  feeAmount: string
): ContractCall => ({
  args: {
    address: sourceBridge as `0x${string}`,
    abi: IERC20TokenTransferrer,
    functionName: 'send',
    args: [
      {
        destinationBlockchainID: destinationChain,
        destinationTokenTransferrerAddress: destinationBridge,
        recipient: destinationAddress as `0x${string}`,
        primaryFeeTokenAddress: feeAddress,
        primaryFee: parseEther(feeAmount),
        secondaryFee: BigInt(0),
        requiredGasLimit: 100000,
        multiHopFallback: zeroAddress,
      },
      parseEther(amount),
    ],
  },
  fallbackGas: DEFAULT_BRIDGE_GAS_LIMIT,
})

const useBridge = () => {
  const { address } = useAuth()

  const { readContract } = useChainFunctions(l1NativeWagmiConfig)
  const { readContract: readContractAvax } = useChainFunctions(avalancheWagmiConfig)

  // L1 native (l1 subnet) -> L1 ERC20 (avax C)
  const nativeL1ToAvax = useCallback(
    (destination: string, amount: string) => [
      contractCallFromNative(
        BRIDGE_L1_NATIVE_HOME_ADDRESS,
        AVAX_CHAIN_BLOCKCHAIN_ID,
        BRIDGE_L1_ERC20_REMOTE_ADDRESS,
        destination,
        amount,
        zeroAddress,
        '0'
      ),
    ],
    []
  )

  // AVAX ERC20 (l1 subnet) -> AVAX native (avax C)
  const erc20AvaxToAvax = useCallback(
    async (destination: string, amount: string) => {
      // Detect if approval is needed
      const allowance = await readContract({
        address: BRIDGE_AVAX_ERC20_REMOTE_ADDRESS as `0x${string}`,
        abi: erc20Abi,
        functionName: 'allowance',
        args: [address as `0x${string}`, BRIDGE_AVAX_ERC20_REMOTE_ADDRESS as `0x${string}`],
      })
      const spendingAmount = '1000000000' // Arbitrary large number for approval (1B tokens)
      const allowanceCall: ContractCall = {
        args: {
          address: BRIDGE_AVAX_ERC20_REMOTE_ADDRESS as `0x${string}`,
          abi: erc20Abi,
          functionName: 'approve',
          args: [BRIDGE_AVAX_ERC20_REMOTE_ADDRESS as `0x${string}`, parseEther(spendingAmount)],
        },
        fallbackGas: DEFAULT_ERC20_APPROVE_GAS_LIMIT,
      }
      const bridgeCall = contractCallFromErc20(
        BRIDGE_AVAX_ERC20_REMOTE_ADDRESS,
        AVAX_CHAIN_BLOCKCHAIN_ID,
        BRIDGE_AVAX_NATIVE_HOME_ADDRESS,
        destination,
        amount,
        zeroAddress,
        '0'
      )
      if ((allowance as bigint) < parseEther(amount)) {
        return [allowanceCall, bridgeCall]
      }
      return [bridgeCall]
    },
    [address, readContract]
  )

  // L1 ERC20 (avax C) -> L1 native (l1 subnet)
  const erc20L1ToLamina1 = useCallback(
    async (destination: string, amount: string) => {
      // Detect if approval is needed
      const allowance = await readContractAvax({
        address: BRIDGE_L1_ERC20_REMOTE_ADDRESS as `0x${string}`,
        abi: erc20Abi,
        functionName: 'allowance',
        args: [address as `0x${string}`, BRIDGE_L1_ERC20_REMOTE_ADDRESS as `0x${string}`],
      })
      const spendingAmount = '1000000000' // Arbitrary large number for approval (1B tokens)
      const allowanceCall: ContractCall = {
        args: {
          address: BRIDGE_L1_ERC20_REMOTE_ADDRESS as `0x${string}`,
          abi: erc20Abi,
          functionName: 'approve',
          args: [BRIDGE_L1_ERC20_REMOTE_ADDRESS as `0x${string}`, parseEther(spendingAmount)],
        },
        fallbackGas: DEFAULT_ERC20_APPROVE_GAS_LIMIT,
      }
      const bridgeCall = contractCallFromErc20(
        BRIDGE_L1_ERC20_REMOTE_ADDRESS,
        LAMINA1_CHAIN_BLOCKCHAIN_ID,
        BRIDGE_L1_NATIVE_HOME_ADDRESS,
        destination,
        amount,
        zeroAddress,
        '0'
      )
      if ((allowance as bigint) < parseEther(amount)) {
        return [allowanceCall, bridgeCall]
      }
      return [bridgeCall]
    },
    [address, readContractAvax]
  )

  // AVAX native (avax C) -> AVAX ERC20 (l1 subnet)
  const nativeAvaxToLamina1 = useCallback(
    (destination: string, amount: string) => [
      contractCallFromNative(
        BRIDGE_AVAX_NATIVE_HOME_ADDRESS,
        LAMINA1_CHAIN_BLOCKCHAIN_ID,
        BRIDGE_AVAX_ERC20_REMOTE_ADDRESS,
        destination,
        amount,
        zeroAddress,
        '0'
      ),
    ],
    []
  )

  return {
    nativeL1ToAvax,
    erc20AvaxToAvax,
    erc20L1ToLamina1,
    nativeAvaxToLamina1,
  }
}

export default useBridge
