import { Stack, Text, Title, Tooltip, useMantineTheme } from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'
import { IconLockFilled } from '@tabler/icons-react'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { AVAX_CHAIN_TICKER, NATIVE_CHAIN_TICKER } from '@/constants/blockchain'
import { useBalancesContext } from '@/contexts/BalancesContext'
import { useModalContext } from '@/contexts/ModalContext'
import { ProcessedBalance } from '@/utils/utils'

import classesActivity from '../Activity/Activity.module.css'
import DropdownButton from '../Buttons/DropdownButton'
import RootButton from '../Buttons/RootButton'
import ErrorMessage from '../ErrorMessage'
import Loading from '../Loading'
import TokenDisplay from '../Tokens/TokensDisplay'
import classes from './BalancesDisplay.module.css'

const BalanceAmountDisplay: React.FC<{
  theme: ReturnType<typeof useMantineTheme>
  ticker: string
  balance: ProcessedBalance
  lockBalance?: ProcessedBalance
}> = ({ theme, ticker, balance, lockBalance }) => (
  <Stack className={classes.stackNetworkBalance}>
    <TokenDisplay token={ticker} />
    <Stack style={{ gap: '10px', flexDirection: 'row', alignItems: 'baseline' }}>
      {lockBalance && (
        <Stack style={{ gap: '5px', flexDirection: 'row', alignItems: 'center' }}>
          <IconLockFilled color={theme.colors.paragraph[0]} size="12px" />
          <Tooltip
            className={classesActivity.fitContentText}
            label={lockBalance.fullValue}
            offset={0}
          >
            <Text>{`${lockBalance.display}`}</Text>
          </Tooltip>
        </Stack>
      )}
      <Tooltip className={classesActivity.fitContentText} label={balance.fullValue} offset={0}>
        <Text className={classes.balanceAmount}>{`${balance.display}`}</Text>
      </Tooltip>
    </Stack>
  </Stack>
)

interface NetworkBalancesProps {
  network: string
  nativeBalance: ProcessedBalance
  erc20Balance?: ProcessedBalance
  rl1Balance?: ProcessedBalance
  ll1Balance?: ProcessedBalance
  stakeBalance?: ProcessedBalance
  ll1Locked?: ProcessedBalance
  rl1Locked?: ProcessedBalance
  closePopup?: () => void
}

const NetworkBalances: React.FC<NetworkBalancesProps> = ({
  network,
  nativeBalance,
  erc20Balance,
  rl1Balance,
  ll1Balance,
  stakeBalance,
  ll1Locked,
  rl1Locked,
  closePopup,
}) => {
  const theme = useMantineTheme()
  const { openModal } = useModalContext()
  // Logged In Info
  const isMetamask = localStorage.getItem('Web3Auth-cachedAdapter') === 'metamask'

  const openTokenModal = useCallback(
    () => (network === 'Lamina1 Network' ? openModal('watchTokens') : openModal('watchTokensAvax')),
    [network]
  )

  return (
    <Stack style={{ gap: '8px', minHeight: '180px' }}>
      <BalanceAmountDisplay
        theme={theme}
        ticker={network === 'Lamina1 Network' ? NATIVE_CHAIN_TICKER : AVAX_CHAIN_TICKER}
        balance={nativeBalance}
      />
      {erc20Balance && (
        <BalanceAmountDisplay
          theme={theme}
          ticker={network !== 'Lamina1 Network' ? NATIVE_CHAIN_TICKER : AVAX_CHAIN_TICKER}
          balance={erc20Balance}
        />
      )}
      {ll1Balance && (
        <BalanceAmountDisplay
          theme={theme}
          ticker="LL1"
          balance={ll1Balance}
          lockBalance={ll1Locked}
        />
      )}
      {rl1Balance && (
        <BalanceAmountDisplay
          theme={theme}
          ticker="RL1"
          balance={rl1Balance}
          lockBalance={rl1Locked}
        />
      )}
      {stakeBalance && <BalanceAmountDisplay theme={theme} ticker="STAKE" balance={stakeBalance} />}
      {isMetamask &&
        (ll1Balance || rl1Balance || stakeBalance || network === 'Avalanche Network') && (
          <RootButton
            secondary
            style1={false}
            style={{ width: '100%' }}
            onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
              e.stopPropagation()
              if (closePopup) closePopup()
              openTokenModal()
            }}
          >
            Add To Metamask
          </RootButton>
        )}
    </Stack>
  )
}

interface BalancesDisplayProps {
  closePopup?: () => void
}

const BalancesDisplay: React.FC<BalancesDisplayProps> = ({ closePopup }) => {
  const { t } = useTranslation()
  const {
    l1NetworkL1Balance,
    avaxNetworkAvaxBalance,
    bridge: { hasBridgeAvax, l1NetworkAvaxBalance, hasBridgeL1, avaxNetworkL1Balance },
    ll1: { hasLL1, balance: totalLl1, locked: ll1Locked },
    rl1: { hasStaking, balance: totalRl1, locked: rl1Locked },
    stake,
    isLoading,
    error,
  } = useBalancesContext()
  const theme = useMantineTheme()
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.md})`)
  const networks = hasBridgeAvax || hasBridgeL1 ? ['Lamina1', 'Avalanche'] : ['Lamina1']
  const networkIcons =
    hasBridgeAvax || hasBridgeL1
      ? [
          <TokenDisplay key={NATIVE_CHAIN_TICKER} token={NATIVE_CHAIN_TICKER} onlyIcon />,
          <TokenDisplay key={AVAX_CHAIN_TICKER} token={AVAX_CHAIN_TICKER} onlyIcon />,
        ]
      : [<TokenDisplay key={NATIVE_CHAIN_TICKER} token={NATIVE_CHAIN_TICKER} onlyIcon />]
  const [active, setActive] = useState<string>('Lamina1')

  return (
    <Stack style={{ gap: '30px' }}>
      <Stack style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
        <Title order={isMobile ? 3 : 2}>{t('components.wallet.tokens', 'Tokens')}</Title>
        <DropdownButton
          width="160px"
          options={networks}
          optionIcons={networkIcons}
          selectedOption={active}
          setSelectedOption={(selected: string) => setActive(selected)}
          backgroundColor={theme.colors.background3[0]}
          noBorder
          disabled={!hasBridgeAvax && !hasBridgeL1}
        />
      </Stack>
      {isLoading && <Loading />}
      {error.isError && (
        <ErrorMessage
          message={t('components.wallet.errorLoadingBalance', 'RPC Error loading {{ call }}', {
            call: error.call,
          })}
          details={error.msg}
        />
      )}
      {!isLoading && !error.isError && (
        <Stack style={{ gap: '30px' }}>
          {active === 'Lamina1' ? (
            <NetworkBalances
              network={t('components.wallet.networkL1', 'Lamina1 Network')}
              nativeBalance={l1NetworkL1Balance || '0'}
              erc20Balance={hasBridgeAvax ? l1NetworkAvaxBalance : undefined}
              ll1Balance={hasLL1 ? totalLl1 : undefined}
              ll1Locked={ll1Locked}
              rl1Balance={hasStaking ? totalRl1 : undefined}
              rl1Locked={rl1Locked}
              stakeBalance={hasStaking ? stake : undefined}
              closePopup={closePopup}
            />
          ) : (
            <NetworkBalances
              network={t('components.wallet.networkAVAX', 'Avalanche Network')}
              nativeBalance={avaxNetworkAvaxBalance || '0'}
              erc20Balance={hasBridgeL1 ? avaxNetworkL1Balance : undefined}
              closePopup={closePopup}
            />
          )}
        </Stack>
      )}
    </Stack>
  )
}
export default BalancesDisplay
