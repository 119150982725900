import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { zeroAddress } from 'viem'

import { DEFAULT_GAS_LIMIT } from '@/constants/blockchain'
import { useErrorMessages } from '@/constants/content'
import { avalancheWagmiConfig, l1NativeWagmiConfig } from '@/plugins/auth/config'
import { ErrorMessageDisplay, NetworkOptions, networkOptions } from '@/utils/utils'

import { ContractCall, useChainFunctions, useChainValues } from './useChain'

const useEstimatedFee = (networkOrigin?: NetworkOptions, contractCalls?: ContractCall[]) => {
  const { t } = useTranslation()
  const errorMessages = useErrorMessages()
  const { gasPrice: gasPriceL1 } = useChainValues(l1NativeWagmiConfig.id)
  const { estimateGas, estimateContractGas } = useChainFunctions(l1NativeWagmiConfig)
  const { gasPrice: gasPriceAvax } = useChainValues(avalancheWagmiConfig.id)
  const { estimateGas: estimateGasAvax, estimateContractGas: estimateContractGasAvax } =
    useChainFunctions(avalancheWagmiConfig)

  const [estimatedFee, setEstimatedFee] = useState<number>(0)
  const [gasPrice, setGasPrice] = useState<number>(0)
  const [gas, setGas] = useState<string>('0')
  const [loadingFees, setLoadingFees] = useState<boolean>(false)
  const [errorFees, setErrorFees] = useState<ErrorMessageDisplay>()
  const web3authGasEstimate = useCallback(async () => {
    try {
      const getEstimatedGas = async () => {
        setLoadingFees(true)
        if (networkOrigin !== networkOptions.avax) {
          setGasPrice(gasPriceL1)
          if (contractCalls && contractCalls.length > 0) {
            const gasAmount = (
              await Promise.all(
                contractCalls.map(call =>
                  estimateContractGas(call.args, call.fallbackGas || DEFAULT_GAS_LIMIT)
                )
              )
            ).reduce((acc, gasValue) => acc + gasValue, 0)
            if (gasAmount && gasPriceL1) {
              setEstimatedFee(gasAmount * (gasPriceL1 * 1e-18 * 1.1))
              setGas((gasAmount * 1.1).toFixed(0))
            }
          } else {
            const gasAmount = await estimateGas(zeroAddress, '0')
            if (gasAmount && gasPriceL1) {
              setEstimatedFee(gasAmount * (gasPriceL1 * 1e-18 * 1.1))
              setGas((gasAmount * 1.1).toFixed(0))
            }
          }
        } else {
          setGasPrice(gasPriceAvax)
          if (contractCalls && contractCalls.length > 0) {
            const gasAmount = (
              await Promise.all(
                contractCalls.map(call =>
                  estimateContractGasAvax(call.args, call.fallbackGas || DEFAULT_GAS_LIMIT)
                )
              )
            ).reduce((acc, gasValue) => acc + gasValue, 0)
            if (gasAmount && gasPriceAvax) {
              setEstimatedFee(gasAmount * (gasPriceAvax * 1e-18 * 1.1))
              setGas((gasAmount * 1.1).toFixed(0))
            }
          } else {
            const gasAmount = await estimateGas(zeroAddress, '0')
            if (gasAmount && gasPriceAvax) {
              setEstimatedFee(gasAmount * (gasPriceAvax * 1e-18 * 1.1))
              setGas((gasAmount * 1.1).toFixed(0))
            }
          }
        }
      }
      await getEstimatedGas()
      setLoadingFees(false)
    } catch (err: any) {
      // Set default fee if error occurs to avoid looping
      setEstimatedFee(0.0005775)
      // Set default gas limit if error occurs
      setGas('250000')
      setLoadingFees(false)

      if (err.message.includes('Failed to fetch')) {
        setErrorFees({ message: errorMessages.rpcConnection, details: err.message })
      } else if (err.message.includes('exceeds the balance of the account')) {
        // noop
      } else {
        setErrorFees({
          message: t('components.send.errorFees', 'Error getting fees'),
          details: err.message,
        })
      }
    }
  }, [
    networkOrigin,
    contractCalls,
    estimateGas,
    estimateContractGas,
    gasPriceL1,
    estimateGasAvax,
    estimateContractGasAvax,
    gasPriceAvax,
  ])

  // Get gas estimate on load or when contract call args change
  useEffect(() => {
    web3authGasEstimate()
  }, [web3authGasEstimate, contractCalls])

  return { estimatedFee, loadingFees, errorFees, gas, gasPrice }
}
export default useEstimatedFee
