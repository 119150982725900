import { Stack, Title } from '@mantine/core'
import { useMemo } from 'react'

import avaxToken from '@/assets/tokens/avaxToken.svg'
import l1TokenBlack from '@/assets/tokens/l1TokenBlack.svg'
import l1TokenWhite from '@/assets/tokens/l1TokenWhite.svg'
import rAndll1TokenBlack from '@/assets/tokens/r&ll1TokenBlack.svg'
import rAndll1TokenWhite from '@/assets/tokens/r&ll1TokenWhite.svg'
import { AVAX_CHAIN_TICKER, NATIVE_CHAIN_TICKER } from '@/constants/blockchain'

import IconWrapper from '../MediaWrappers/IconWrapper'

interface TokenDisplayProps {
  token: string
  onlyIcon?: boolean
  onlyTicker?: boolean
  size?: number | string
}

const TokenDisplay: React.FC<TokenDisplayProps> = ({
  token,
  onlyIcon = false,
  onlyTicker = false,
  size = 24,
}) => {
  const tokenIconDark = useMemo(
    () =>
      token === NATIVE_CHAIN_TICKER
        ? l1TokenWhite
        : token === AVAX_CHAIN_TICKER
          ? avaxToken
          : rAndll1TokenWhite,
    [token]
  )
  const tokenIconLight = useMemo(
    () =>
      token === NATIVE_CHAIN_TICKER
        ? l1TokenBlack
        : token === AVAX_CHAIN_TICKER
          ? avaxToken
          : rAndll1TokenBlack,
    [token]
  )

  return (
    <Stack style={{ flexDirection: 'row', alignItems: 'start' }}>
      {!onlyTicker && (
        <IconWrapper
          style={{ height: size, width: size, alignSelf: 'center' }}
          iconDark={tokenIconDark}
          iconLight={tokenIconLight}
        />
      )}
      {!onlyIcon && <Title order={4}>{token}</Title>}
    </Stack>
  )
}
export default TokenDisplay
