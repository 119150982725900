import { Stack } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { parseEther, TransactionReceipt } from 'viem'

import {
  AVAX_CHAIN_TICKER,
  BRIDGE_RELAYER_ADDRESS,
  BRIDGE_RELAYER_FEE,
} from '@/constants/blockchain'
import { useBalancesContext } from '@/contexts/BalancesContext'
import { networkOptions } from '@/utils/utils'

import RootButton from '../Buttons/RootButton'
import CurrencyInput from '../CurrencyInput'
import ModalSkeleton from '../Modals/ModalSkeleton'
import ConfirmTransaction from '../Modals/Send/ConfirmTransaction'
import TooltipTitle from '../TooltipTitle'

interface PayBridgeFeeProps {
  relayerFeePaid: React.MutableRefObject<boolean | undefined>
  relayerFeeLoading: React.MutableRefObject<boolean>
  networkDestination: string
  sendTransferAvax: (
    to: string,
    amount: string,
    isRelayer: boolean
  ) => Promise<string | TransactionReceipt>
  setSendingTx: (sendingTx: boolean) => void
  setError: (error: string) => void
  openError: () => void
  updateOkToClose: () => void
}

const PayBridgeFee: React.FC<PayBridgeFeeProps> = ({
  relayerFeePaid,
  relayerFeeLoading,
  networkDestination,
  sendTransferAvax,
  setSendingTx,
  setError,
  openError,
  updateOkToClose,
}) => {
  const { t } = useTranslation()

  // Confirmation modal for payment of bridge fee
  const [openedPaymentRelayerTx, { open: openPaymentRelayerTx, close: closePaymentRelayerTx }] =
    useDisclosure(false)

  // Check if there's enough AVAX balance for the transaction
  const { avaxNetworkAvaxBalance, error: balanceError } = useBalancesContext()
  const enoughAvaxBalance = useMemo(
    () => !balanceError.isError && avaxNetworkAvaxBalance.value >= parseEther(BRIDGE_RELAYER_FEE),
    [balanceError, avaxNetworkAvaxBalance.value]
  )

  // TODO: Change the relayer fee to be dynamic and dependent on index queries
  // A: query # txs between the address and RELAYER_ADDRESS of RELAYER_FEE amount
  // B: query # txs of the address relying on the bridge when dependent on that fee
  // if A > B then PAID otherwise TO PAY

  // Send Transaction AVAX on AVAX to Relayer Address
  const sendRelayerFee = useCallback(async () => {
    closePaymentRelayerTx()
    try {
      relayerFeeLoading.current = true
      const receipt = (await sendTransferAvax(
        BRIDGE_RELAYER_ADDRESS,
        BRIDGE_RELAYER_FEE,
        true
      )) as TransactionReceipt
      console.log('receipt', receipt)
      // if transaction failed, throw error
      if (receipt?.status !== 'success') {
        throw new Error('Relayer fee payment failed')
      }
      // set relayer fee paid
      relayerFeeLoading.current = false
      relayerFeePaid.current = true
      // update okToClose status here given it relies on relayer fee paid and it is updated here, not triggered an update on state of Send component
      updateOkToClose()
    } catch (err: any) {
      relayerFeeLoading.current = false
      setSendingTx(false)
      if (!err.message.includes('User rejected the request.')) {
        setError(err.message)
        openError()
      }
    }
  }, [
    relayerFeeLoading,
    relayerFeePaid,
    sendTransferAvax,
    closePaymentRelayerTx,
    setSendingTx,
    setError,
    openError,
  ])
  return (
    <>
      <ModalSkeleton
        opened={openedPaymentRelayerTx}
        onClose={closePaymentRelayerTx}
        size="420px"
        title={t('components.send.confirmTransaction', 'Confirm Transaction')}
      >
        <ConfirmTransaction
          close={closePaymentRelayerTx}
          confirm={sendRelayerFee}
          message={t(
            'components.send.confirmBridgeFeeTransaction',
            'Once you begin this transaction you must complete it by pressing the SEND button on the next screen. If you leave this window without finishing the transaction you will lose the bridge fee and have to repay it to send another transaction. Are you sure you want to continue?'
          )}
        />
      </ModalSkeleton>
      <Stack
        style={{
          margin: '5px 0',
          gap: 10,
          flexDirection: 'column',
        }}
      >
        <Stack
          style={{
            flexDirection: 'row',
            gap: 15,
            justifyContent: 'space-between',
          }}
        >
          <TooltipTitle
            title={t('components.send.bridgeFee', 'Bridge Fee')}
            tooltip={t(
              'components.send.bridgeFeeTooltip',
              'This fee is paid in {{token}} on the {{dest}} to the bridge relayer to facilitate the cross-chain transaction',
              {
                token: AVAX_CHAIN_TICKER,
                dest: networkDestination === networkOptions.avax ? 'Avalanche C-Chain' : 'Lamina1',
              }
            )}
          />
          <RootButton
            style1
            secondary={!enoughAvaxBalance}
            onClick={openPaymentRelayerTx}
            style={{ height: '3em' }}
            disabled={relayerFeePaid.current || relayerFeeLoading.current || !enoughAvaxBalance}
          >
            {enoughAvaxBalance
              ? relayerFeeLoading.current
                ? t('buttons.loading', 'Loading...')
                : relayerFeePaid.current
                  ? t('buttons.paid', 'Paid')
                  : t('buttons.pay', 'Pay')
              : t('buttons.notEnoughTokens', 'Not Enough {{token}}', {
                  token: AVAX_CHAIN_TICKER,
                })}
          </RootButton>
        </Stack>
        <CurrencyInput
          amount={{
            value: BRIDGE_RELAYER_FEE,
            touched: false,
            error: '',
          }}
          setAmount={() => {}}
          token={AVAX_CHAIN_TICKER}
          setToken={() => {}}
          estimatedFee={0}
          networkOrigin={networkOptions.avax}
          disabledDropdown
          disabledInput
          allowMax={false}
        />
      </Stack>
    </>
  )
}

export default PayBridgeFee
