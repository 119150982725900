import { Grid, Stack } from '@mantine/core'
import React from 'react'

import { useChainFeaturesContext } from '@/contexts/ChainFeaturesContext'
import { EconomicsProvider } from '@/contexts/EconomicsContext'

import BalancesDisplay from './BalancesDisplay'
import RewardsDisplay from './RewardsDisplay'
import WalletControl from './WalletControl'

interface WalletManagementProps {
  closePopup?: () => void
}

const WalletManagement: React.FC<WalletManagementProps> = ({ closePopup = undefined }) => {
  const { hasFeature } = useChainFeaturesContext()
  const hasBridgeL1 = hasFeature('bridge-l1')
  const hasBridgeAvax = hasFeature('bridge-avax')
  const hasStaking = hasFeature('staking')
  const hasLL1 = hasFeature('ll1')
  return (
    <Stack style={{ gap: closePopup ? '30px' : '60px', minHeight: '500px' }}>
      <WalletControl closePopup={closePopup} />
      {(hasLL1 || hasStaking || hasBridgeL1 || hasBridgeAvax) && (
        <Grid gutter={closePopup ? '30px' : '60px'}>
          <Grid.Col span="content">
            <BalancesDisplay closePopup={closePopup} />
          </Grid.Col>
          {(hasLL1 || hasStaking) && (
            <Grid.Col span="content">
              <EconomicsProvider>
                <RewardsDisplay closePopup={closePopup} />
              </EconomicsProvider>
            </Grid.Col>
          )}
        </Grid>
      )}
    </Stack>
  )
}

export default WalletManagement
